import React, { useState } from 'react';
import './header.css';

function Header({ selectedTab, setSelectedTab }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <section className="header-left">
        <img src={`./logos/Logo_White.png`} alt="Logo" className="logo-white" />
      </section>
      <nav className={`header-center tab-list ${isMenuOpen ? 'open' : ''}`}>
        {['Home', 'Projects', 'Blog', 'About', 'Contact'].map((tab) => (
          <button
            key={tab}
            onClick={() => {
              setSelectedTab(tab);
              setIsMenuOpen(false);
            }}
            className={selectedTab === tab ? 'selected' : ''}
            disabled={selectedTab === tab}
          >
            {tab}
          </button>
        ))}
      </nav>
      <section className="header-right widget-spotify">
        {/* Spotify API */}
      </section>
      <div className="menu-icon" onClick={handleMenuToggle}>
        &#9776;
      </div>
    </header>
  );
}

export default Header;
