import React from 'react';
import './blog.css';
function Blog() {
  return (
    <main>
      <section className='blog'>
        <h1>Blog</h1>
      </section>
      <section className='content'>
        <p>Content for the Blog page goes here.</p>
      </section>
    </main>
  );
}

export default Blog;
