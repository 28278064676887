import React from 'react';
import './home.css';
import Bringle from '../../assets/images/Bringle_Lake_Sunset.jpg';
function Home() {
  return (
    <main>
      <section className="hero-section">
        <img src={Bringle} alt="Sunset over Bringle Lake" />
          <h1>Karituo Luster</h1>
          <h2>Software Engineer</h2>
      </section>
      <section className="content"></section>
    </main>
  );
}

export default Home;
