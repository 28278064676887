import React, { useState } from 'react';
import './contact-form.css';

function ContactForm() {
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('http://localhost:3001/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, title, body })
    })
    .then(response => {
      if (response.ok) {
        console.log('Email sent successfully');
        // Reset form fields
        setEmail('');
        setTitle('');
        setBody('');
      } else {
        console.error('Failed to send email');
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
        required
      />
      <textarea
        value={body}
        onChange={(e) => setBody(e.target.value)}
        placeholder="Body"
        required
      />
      <button type="submit">Send</button>
    </form>
  );
}

export default ContactForm;
