import React from 'react';
import './projects.css';
function Projects() {
  return (
    <main>
      <section className='projects'>
        <h1>Projects</h1>
      </section>
      <section className='content'>

      </section>
    </main>
  );
}

export default Projects;
