import React from 'react';
import './footer.css';
import FacebookIcon from '../../assets/icons/icon-facebook.png';
import InstagramIcon from '../../assets/icons/icon-instagram.png';
import GithubIcon from '../../assets/icons/icon-github.png';
import LinkedinIcon from '../../assets/icons/icon-linkedin.png';
import SpotifyIcon from '../../assets/icons/icon-spotify.png';

function Footer() {
  return (
    <footer>
      <section className="footer-left">
        <img src={`${process.env.PUBLIC_URL}/logos/Logo_Colored.png`} className="logo-colored" alt="Karituo Luster Logo" />
        <div className="social-container">
          <a href="https://www.facebook.com/Tommy25Hoang" target="_blank" rel="noopener noreferrer">
            <img className="icon-social" src={FacebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/karituo_luster/" target="_blank" rel="noopener noreferrer">
            <img className="icon-social" src={InstagramIcon} alt="Instagram" />
          </a>
          <a href="https://github.com/Karituo-Luster" target="_blank" rel="noopener noreferrer">
            <img className="icon-social" src={GithubIcon} alt="GitHub" />
          </a>
          <a href="https://www.linkedin.com/in/tommy25hoang/" target="_blank" rel="noopener noreferrer">
            <img className="icon-social" src={LinkedinIcon} alt="LinkedIn" />
          </a>
          <a href="https://open.spotify.com/user/1239881456" target="_blank" rel="noopener noreferrer">
            <img className="icon-social" src={SpotifyIcon} alt="Spotify" />
          </a>
        </div>
      </section>
      <h2 className="footer-title">Karituo Luster</h2>
      <section className="footer-center widget-stocks">
        {/* Stocks API */}
      </section>
      <section className="footer-right widget-weather">
        {/* Weather API */}
      </section>
    </footer>
  );
}

export default Footer;
