// App.js
import React, { useState } from 'react';
import './styles/App.css';
import Header from './components/header/header';
import Home from './pages/home/home';
import Projects from './pages/projects/projects';
import Blog from './pages/blog/blog';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Error from './pages/error/error';
import Footer from './components/footer/footer';

function App() {
  const [selectedTab, setSelectedTab] = useState('Home');
  const [isHidden, setIsHidden] = useState(false);

  const renderMainContent = () => {
    switch (selectedTab) {
      case 'Home':
        return <Home />;
      case 'Projects':
        return <Projects />;
      case 'Blog':
        return <Blog />;
      case 'About':
        return <About />;
      case 'Contact':
        return <Contact />;
      default:
        return <Error />;
    }
  };

  const handleTabChange = (tab) => {
    setIsHidden(true);
    setTimeout(() => {
      setSelectedTab(tab);
      setIsHidden(false);
    }, 500);
  };

  return (
    <div className="App">
      <Header selectedTab={selectedTab} setSelectedTab={handleTabChange} />
      <main className={isHidden ? 'hide' : ''}>{renderMainContent()}</main>
      <Footer />
    </div>
  );
}

export default App;
