import React from 'react';
import './contact.css';
import ContactForm from '../../components/contact-form/contact-form';
function Contact() {
  return (
    <main>
      <section className='contact'>
        <h1>Contact Me</h1>
      </section>
      <section className='content'>
        <ContactForm />
      </section>
    </main>
  );
}

export default Contact;