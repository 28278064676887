import React from 'react';
import './about.css';

function About() {
  return (
    <main>
      <section className='about'>
        <h1>Karituo Luster</h1>
        <h2>Tommy Hoang</h2>
      </section>
      <section className='content'>
        <p>Good day friends, I'm Tommy.</p>
      </section>
    </main>
  );
}

export default About;
