import React from 'react';
import './error.css';
function Error() {
  return (
    <main>
      <section className='error'>
        <h1>Error</h1>
        <h2>I don't know how this could be possible.</h2>
      </section>
    </main>
  );
}

export default Error;
